var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogVisible),expression:"dialogVisible"}],ref:"dragBox",staticClass:"layerBox",attrs:{"id":"dragBox"}},[_c('div',{staticClass:"title",on:{"mousedown":_vm.move}},[_c('div',[_c('span',{staticClass:"title-content"},[_vm._v(" "+_vm._s(_vm.stationName)+" ")]),_c('span',{staticClass:"caoz"},[_c('i',{staticClass:"iconfont icon-shanchu2",staticStyle:{"float":"right","padding-right":"10px"},on:{"click":function($event){return _vm.colse()}}}),_c('i',{staticClass:"iconfont icon-yidong",staticStyle:{"float":"right","padding-right":"13px"}})])])]),_c('div',[_c('div',{staticClass:"Feature"},[_c('div',{staticClass:"btnDiv",staticStyle:{"margin-top":"10px"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.videoAdd()}}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v("新增 ")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","header-cell-style":{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign: 'left',
              background: '#f8f8f9',
            },"stripe":true}},[_c('el-table-column',{attrs:{"prop":"monitorArea","label":"监控区域","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"agreement","label":"协议地址","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","align":"left","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.getDetail(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.deleteById(row.id)}}},[_vm._v("删除")])]}}])})],1)],1)])]),_c('el-dialog',{attrs:{"title":"视频监控","visible":_vm.dialogFormVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"100px","inline":true,"size":"small"}},[_c('el-form-item',{attrs:{"label":"监控区域","prop":"monitorArea"}},[_c('el-input',{style:(_vm.breadth),attrs:{"type":"textarea","rows":2,"maxlength":"40","placeholder":"请输入监控区域名称","clearable":""},model:{value:(_vm.form.monitorArea),callback:function ($$v) {_vm.$set(_vm.form, "monitorArea", $$v)},expression:"form.monitorArea"}})],1),_c('el-form-item',{attrs:{"label":"协议地址"}},[_c('el-input',{style:(_vm.breadth),attrs:{"type":"textarea","rows":2,"maxlength":"40","placeholder":"请输入协议地址","clearable":""},model:{value:(_vm.form.agreement),callback:function ($$v) {_vm.$set(_vm.form, "agreement", $$v)},expression:"form.agreement"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.closeVideoDiag()}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }