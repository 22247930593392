import request from '../utils/request'

export default {
  getFindTree(params) {
    return request.get('/layer/findTree', { params })
  },
  addSave(data) {
    return request.post('/layer/save', data)
  },
  setSort(data) {
    return request.post('/layer/sort', data)
  },
  getDelete(params) {
    return request.get('/layer/delete', { params })
  },
  update(data) {
    return request.post('/layer/update', data)
  },
  layerChange(data) {
    return request.post('/layer/change', data)
  },
  enterpriseOptions(data) {
    return request.get('/options/enterprise', data)
  },
  exportExcel(params) {
    return request.get('/layer/exportExcel', { params, responseType: 'arraybuffer' })
  },

  // getList(params) {
  //   return request.get('/configShow/findListByDictCode', { params })
  // },

  // setSort(data) {
  //   return request.post('/configShow/sort', data)
  // }
  
  
  layerGroup(params) {
    return request.get('/layer/group', {params})
  },
}
