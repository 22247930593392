import axios from '@/common/js/request'

//场站列表-分页
export function pageStandingBook(data){
    const pageStandingBook = axios({
        url: '/station/pageStandingBook',
        method: 'get',
        params:data
    })
    return pageStandingBook
}

//场站列表-导出excel
export function exportStandingBook(params){
    const exportStandingBook = axios({
        url: '/station/exportStandingBook',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return exportStandingBook
}
//场站-删除
export function deleteStationById(data){
    const deleteStationById = axios({
        url: '/station/deleteStationById',
        method: 'get',
        params:data
    })
    return deleteStationById
}

//场站-视频监控-列表
export function getVideoMonitorList(data){
    const getVideoMonitorList = axios({
        url: '/station/getVideoMonitorList',
        method: 'get',
        params:data
    })
    return getVideoMonitorList
}
//场站-视频监控-获取详情
export function getVideoMonitorById(data){
    const getVideoMonitorById = axios({
        url: '/station/getVideoMonitorById',
        method: 'get',
        params:data
    })
    return getVideoMonitorById
}
//场站-视频监控-删除
export function deleteVideoMonitor(data){
    const deleteVideoMonitor = axios({
        url: '/station/deleteVideoMonitor',
        method: 'get',
        params:data
    })
    return deleteVideoMonitor
}
//场站-视频监控-新增
export function saveVideoMonitor(data) {
    const saveVideoMonitor = axios({
        url: '/station/saveVideoMonitor',
        method: 'post',
        data
    })
    return saveVideoMonitor
}
//场站-视频监控-编辑保存
export function updateVideoMonitor(data) {
    const updateVideoMonitor = axios({
        url: '/station/updateVideoMonitor',
        method: 'post',
        data
    })
    return updateVideoMonitor
}

//管线列表-分页
export function pipePageStandingBook(data){
    const pipePageStandingBook = axios({
        url: '/pipe/pageStandingBook',
        method: 'get',
        params:data
    })
    return pipePageStandingBook
}
//管线列表-导出excel
export function pipeExportStandingBook(params){
    const pipeExportStandingBook = axios({
        url: '/pipe/exportStandingBook',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return pipeExportStandingBook
}
//管线统计列表-分页
export function pageStatisticsStandingBook(data){
    const pageStatisticsStandingBook = axios({
        url: '/pipe/pageStatisticsStandingBook',
        method: 'get',
        params:data
    })
    return pageStatisticsStandingBook
}
export function exportStatisticsStandingBook(params){
    const exportStatisticsStandingBook = axios({
        url: '/pipe/exportStatisticsStandingBook',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return exportStatisticsStandingBook
}

//阀门列表-分页
export function valvePageStandingBook(data){
    const valvePageStandingBook = axios({
        url: '/valve/pageStandingBook',
        method: 'get',
        params:data
    })
    return valvePageStandingBook
}
//阀门列表-导出excel
export function valveExportStandingBook(params){
    const valveExportStandingBook = axios({
        url: '/valve/exportStandingBook',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return valveExportStandingBook
}