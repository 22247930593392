<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox">
      <div class="title" @mousedown="move">
        <div>
          <span class="title-content">
            {{stationName}}
          </span>
          <span class="caoz">
            <i
              class="iconfont icon-shanchu2"
              style="float: right; padding-right: 10px;"
              @click="colse()"
            ></i>
            <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
          </span>
        </div>
      </div>
      <div>
        <div class="Feature">
          <div class="btnDiv" style="margin-top:10px">
            <el-button type="primary" size="mini" @click="videoAdd()">
              <i class="el-icon-plus"></i>新增
            </el-button>
          </div>
          <el-table
              :data="tableData"
              style="width: 100%"
              border
              :header-cell-style="{
                color: '#333',
                fontFamily: 'MicrosoftYaHeiUI',
                fontSize: '14px',
                fontWeight: 900,
                textAlign: 'left',
                background: '#f8f8f9',
              }"
              :stripe="true"
            >
            <el-table-column
              prop="monitorArea"
              label="监控区域"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="agreement"
              label="协议地址"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" align="left" width="200">
              <template slot-scope="{row}">
                <el-button type="primary" @click="getDetail(row)" size="mini">编辑</el-button>
                <el-button type="primary" @click="deleteById(row.id)" size="mini">删除</el-button>
              </template> 
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog 
      title="视频监控" 
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" :inline="true" size="small">
        <el-form-item label="监控区域" prop="monitorArea">
          <el-input 
            v-model="form.monitorArea" 
            type="textarea"
            :rows="2"
            maxlength=40 
            placeholder="请输入监控区域名称" 
            :style="breadth"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="协议地址">
          <el-input 
            type="textarea"
            :rows="2"
            v-model="form.agreement" 
            maxlength=40 
            placeholder="请输入协议地址" 
            :style="breadth"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeVideoDiag()">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>    
  </div>
</template>
<script>
import '../Gis/style/skin.scss'
import { deleteVideoMonitor, getVideoMonitorById, getVideoMonitorList, saveVideoMonitor, updateVideoMonitor } from '../../RequestPort/gisbook'

export default {
  data() {
    return {
      dialogVisible: false,
      dialogFormVisible:false,
      stationName: '',
      stationId:'',
      form: {},
      rules: {
        monitorArea: [{ required: true, message: '请输入监控区域名称', trigger: 'blur' }]
      },
      breadth: "width:350px",
      tableData:[]
    }
  },
  mounted() {},
  methods: {
    openDialog(obj) {
      this.getList(obj.id)
      this.stationId = obj.id
      this.stationName = obj.stationName
      this.dialogVisible = true
    },
    colse() {
      this.dialogVisible = false
    },    
    getList(id){
      getVideoMonitorList({id:id}).then(res=>{
        this.tableData = res.data
      })     
    },
    videoAdd(){
      this.dialogFormVisible = true
    },
    closeVideoDiag(){
      this.dialogFormVisible = false
      this.form = {}
      this.$refs.ruleForm.resetFields();
    },
    submit(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let p = {
            ...this.form,
            stationId:this.stationId
          }
          if(this.form.id){
            updateVideoMonitor(p).then(res => {
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.closeVideoDiag()
              }else{
                this.$message.error(res.msg)
              }
              this.getList(this.stationId)
            })
          }else{
            saveVideoMonitor(p).then(res => {
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.closeVideoDiag()
              }else{
                this.$message.error(res.msg)
              }
              this.getList(this.stationId)
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    deleteById(id){
      this.$confirm(`确认删除视频监控信息？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        deleteVideoMonitor({id:id}).then(res=>{
          if(res.code==200){ 
            this.$message.success(res.msg)
          }else{ 
            this.$message.error(res.msg)
          }
          this.getList(this.stationId)
        })
      });
    },
    getDetail(row){
      getVideoMonitorById({ id: row.id }).then(res => {
        if(res.code==200){
          this.form = {
            ...res.data,
            stationId:this.stationId
          }
          this.dialogFormVisible = true
        }else{
          this.$message.error(res.msg)
        }
      })
    },    
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }    
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
  background-color: var(--background-color-skin);
}
::v-deep .el-tabs__item {
  color: var(--font-color-c1);
}
::v-deep .el-tabs__active-bar {
  background-color: #6eb7e3;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
::v-deep .formItemSmall.el-form-item {
  margin-bottom: 2px;
}
.caozu {
  display: inline-flex;
  height: 40px;
  font-size: 14px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 36px;
  color: #a5b4c4;
  float: right;
}
.colseBut {
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
}

.layerBox {
  width: 760px;
  position: absolute;
  top: 4rem;
  left: 10rem;
  min-height: 3rem;
  min-width: 3rem;
  //   border: 1px solid var(--border-color-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  z-index: 2000;
  background: var(--background-color-skin);
  opacity: 0.98;
}
.Feature {
  padding: 5px 20px 10px;
  line-height: 0.28rem;
  // text-align: center;
  // width: 100%;
  color: var(--font-color-f1);
}
.equipment-item {
  text-align: left;
  width: 380px;
  word-break: break-word;
}
.btnDiv {
  margin-bottom: 10px;
  .btn {
    margin-right: 15px;
  }
}
.formbtn {
  text-align: right;
  margin-right: 30px;
  button {
    margin: 7px 0px 0 15px;
  }
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
.selecttag {
  margin-left: 5px;
  cursor: pointer;
}
.selectWidth {
  width: 380px;
}
.title {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
::v-deep .el-tabs__nav-scroll {
  background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
  padding-left: 10px;
  padding-top: 6px;
}
::v-deep .el-tabs__item {
  height: 39px;
  line-height: 39px;
  text-align: center;
  min-width: 110px;
  color: var(--font-color-f1);
}
::v-deep .el-tabs__item.is-active {
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  color: var(--font-color-f1);
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
.title-content {
  color: var(--font-color-f1);
  font-weight: bold;
  padding: 13px 9px;
  font-size: 14px;
}
.caozt {
  position: absolute;
  right: 0px;
  top: 10px;
}
::v-deep .el-tabs--border-card {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
::v-deep .el-form .el-select {
  width: 380px;
}
</style>
